<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_seed.applicant') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('research_seed.seed_class_name')"
                    label-for="seed_class_id"
                >
                    <b-form-select
                        plain
                        id="seed_class_id"
                        :options="seedClassList"
                        v-model="search.seed_class_id"
                        placeholder=""
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.organization')"
                label-for="org_name"
                >
                <b-form-input
                    id="org_name"
                    v-model="search.org_name"
                    placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="5" md="5" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_seed.applicant_name')"
                label-for="name"
                >
                <b-form-input
                    id="name"
                    v-model="search.name"
                    placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
          <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_seed.applicant') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="selected.length" variant="primary" v-b-modal.modal-4 @click="resetId">
              {{ $t('research_seed.review') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(applicationId)="data">
                      {{ $n(data.item.applicationId, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span class="capitalize">{{ data.item.created_at | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(applicant_name)="data">
                      <span class="capitalize">{{ data.item.applicant_name }}</span>
                    </template>
                    <template v-slot:cell(applicant_name_bn)="data">
                      {{ data.item.applicant_name_bn }}
                    </template>
                    <template v-slot:cell(applicant_org_name)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name || getOrgName(data.item.govt_org_id) }}</span>
                    </template>
                    <template v-slot:cell(applicant_org_name_bn)="data">
                      <span class="capitalize">{{ data.item.applicant_org_name_bn || getOrgName(data.item.govt_org_id) }}</span>
                    </template>
                    <template v-slot:cell(seed_name)="data">
                      <span v-if="data.item.seed_type_id != 8" class="capitalize">{{ getSeedName(data.item.seed_type_id) }}</span>
                      <span v-if="data.item.seed_type_id == 8">{{ data.item.seed_name_others}}</span>
                    </template>
                    <!-- <template v-slot:cell(seed_variety)="data">
                      <span class="capitalize">{{ getSeedVariety(data.item.seed_variety_id) }}</span>
                    </template> -->
                    <template v-slot:cell(seed_class)="data">
                      <span class="capitalize">{{ getSeedClass(data.item.seed_class_id) }}</span>
                    </template>
                    <template v-slot:cell(application_status)="data">
                      <span class="badge badge-primary" v-if="data.item.application_status === 1">{{$t('certify_form.pending')}}</span>
                      <span class="badge badge-secondary" v-if="data.item.application_status === 2">{{$t('certify_form.verified')}}</span>
                      <span class="badge badge-dark" v-if="data.item.application_status === 3">{{$t('certify_form.inspection_level_1_done')}}</span>
                      <span class="badge badge-warning" v-if="data.item.application_status === 4">{{$t('certify_form.inspection_level_2_done')}}</span>
                      <span class="badge badge-info" v-if="data.item.application_status === 5">{{$t('certify_form.inspection_level_3_done')}}</span>
                      <span class="badge badge-success" v-if="data.item.application_status === 6">{{$t('globalTrans.approved')}}</span>
                      <span class="badge badge-danger" v-if="data.item.application_status === 7">{{$t('globalTrans.rejected')}}</span>
                      <span class="badge badge-success" v-if="data.item.application_status === 8">{{$t('certify_form.certified')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-badge class="cursor" v-if="data.item.seed_payment === 1 && data.item.position < 4" :title="$t('research_seed.verify')" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="review(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-badge>
                      <b-badge class="cursor" v-if="data.item.position >= 4" :title="$t('research_seed.verification_details')" variant=" iq-bg-success mr-1 mb-1" size="sm" @click="views(data.item.general_info_id)"><i class="ri-pages-line"></i></b-badge>
                      <b-badge class="cursor" :title="$t('research_seed.application_details')" v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item.general_info_id)"><i class="ri-eye-line m-0 "></i></b-badge>
                      <b-badge class="cursor" v-b-modal.modal-12 variant=" iq-bg-success mr-1" size="sm" title="Payment Recipt" @click="appPaymentReceipt(data.item)"><i class="ri-attachment-line m-1"></i></b-badge>
                      <!-- <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="activeOrInactive(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <FormV :id="selected" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="this.$t('research_seed.application_verification')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="application" :key="application" />
    </b-modal>
    <b-modal id="modal-6" size="xl" :title="this.$t('research_seed.details_title')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <AllDetails :id="application" :key="application" />
    </b-modal>
    <b-modal id="modal-12" size="md" :title="$t('research_seed.payment_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <MoneyReceiptV :item="applications" key="application"/>
    </b-modal>
  </b-container>
</template>

<script>
import FormV from './Form.vue'
import Details from './ApplicationDetails.vue'
import AllDetails from './Details'
import MoneyReceiptV from './MoneyReceipt.vue'
// import Details from '../../../../../external-user-service/agri-research/pages/field-application-list/Application.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { applicantList } from '../../../api/routes'
import researchTestingHelper from '@/mixins/agri-research-helpers'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import moment from 'moment'
export default {
  mixins: [ModalBaseMasterList, researchTestingHelper],
  components: {
    FormV,
    Details,
    AllDetails,
    MoneyReceiptV
  },
  data () {
    return {
        datas: [],
        selected: [],
        application: {},
        applications: {},
        search: {
            name: '',
            org_name: '',
            seed_class_id: '',
            office_id: 0,
            org_id: 0
        }
    }
  },
  created () {
    if (this.authUser.role_id === 1) {
    } else if (this.authUser.is_org_admin === 1) {
      this.search.org_id = this.authUser.org_id
    } else if (this.authUser.is_admin === true) {
      this.search.org_id = this.authUser.org_id
      this.search.office_id = this.authUser.office_id
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_seed.document_verification') : this.$t('research_seed.document_verification_modify')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('research_seed.application_id'), class: 'text-left' },
          { label: this.$t('research_seed.application_date'), class: 'text-left' },
          { label: this.$t('research_seed.applicant_name'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('research_seed.seed_name'), class: 'text-left' },
          // { label: this.$t('research_seed.seed_variety'), class: 'text-left' },
          { label: this.$t('research_seed.seed_class_name'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'applicationId' },
          { key: 'created_at' },
          { key: 'applicant_name_bn' },
          { key: 'applicant_org_name_bn' },
          { key: 'seed_name' },
          // { key: 'seed_variety' },
          { key: 'seed_class' },
          { key: 'application_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'applicationId' },
          { key: 'created_at' },
          { key: 'applicant_name' },
          { key: 'applicant_org_name' },
          { key: 'seed_name' },
          // { key: 'seed_variety' },
          { key: 'seed_class' },
          { key: 'application_status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search)
    }
    this.loadData()
  },
  methods: {
    getDate (date) {
        return moment(date).format('L')
    },
    async searchData () {
      this.loadData()
    },
    view (generalInfoId) {
        this.application = generalInfoId
    },
    views (generalInfoId) {
        this.application = generalInfoId
        this.$bvModal.show('modal-5')
    },
    review (item) {
        this.selected = item.general_info_id
    },
    appPaymentReceipt (item) {
      this.applications = item
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, applicantList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
            const allData = this.$store.state.list
            this.datas = JSON.parse(JSON.stringify(allData))
          } else {
            this.$store.dispatch('setList', [])
            this.datas = []
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getOrgName (id) {
      if (id) {
        const tmpOrg = this.$store.state.commonObj.organizationProfileList.find(newItem => newItem.value === parseInt(id))
        if (tmpOrg) {
          if (this.$i18n.locale === 'en') {
            return tmpOrg.text_en
          } else {
            return tmpOrg.text_bn
          }
        } else {
          return ''
        }
      }
    },
    getSeedName (id) {
      if (id > 0) {
        const seed = this.$store.state.AgriResearch.commonObj.seedNameList.find(item => item.value === parseInt(id))
        if (this.$i18n.locale === 'en') {
          return seed.text_en
        } else {
          return seed.text_bn
        }
      }
    },
    getSeedVariety (id) {
      if (id > 0) {
        const seed = this.$store.state.AgriResearch.commonObj.seedVarietyList.find(item => item.value === parseInt(id))
        if (this.$i18n.locale === 'en') {
          return seed.text_en
        } else {
          return seed.text_bn
        }
      }
    },
    getSeedClass (id) {
      if (id > 0) {
        const seed = this.$store.state.AgriResearch.commonObj.seedClassNameList.find(item => item.value === parseInt(id))
        if (this.$i18n.locale === 'en') {
          return seed.text_en
        } else {
          return seed.text_bn
        }
      }
    },
    dataList (data) {
      const listData = data
      return listData
    }
  }
}
</script>
<style scoped>
  .cursor{
    cursor: pointer;
  }
</style>
