<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <!-- <template v-slot:headerAction>
            <router-link to="technology-data-list" :class="'btn btn-primary text-light mr-2'">{{ $t('n_c_data_bank.technology_data') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          </template> -->
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:40%">{{ $t('research_seed.proper_fillup') }}</b-th>
                                <b-td style="width:60%">
                                    <slot v-if="technologyData.proper_fillup == 1">{{ $t('globalTrans.yes') }}</slot>
                                    <slot v-if="technologyData.proper_fillup == 2">{{ $t('globalTrans.no') }}</slot>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:40%">{{ $t('research_seed.correctly_upload') }}</b-th>
                                <b-td style="width:60%">
                                    <slot v-if="technologyData.correctly_upload == 1">{{ $t('globalTrans.yes') }}</slot>
                                    <slot v-if="technologyData.correctly_upload == 2">{{ $t('globalTrans.no') }}</slot>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:40%">{{ $t('research_seed.remarks') }}</b-th>
                                <b-td style="width:60%">{{ ($i18n.locale=='bn') ? technologyData.proper_fillup_remarks : technologyData.proper_fillup_remarks }}</b-td>
                            </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { applicationVerificationShow } from '../../../api/routes'
// import ExportPdf from './export_pdf_details'

export default {
    components: {
    },
    props: ['id'],
    created () {
      if (this.id) {
          this.getTechnologyDataAllInfo()
      }
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
        loading: false,
      load: false,
      technologyData: [],
      agriResearchServiceBaseUrl: agriResearchServiceBaseUrl
    }
  },
  computed: {
    // loading () {
    //   return this.$store.state.commonObj.loading
    // }
  },
  methods: {
    // pdfExport () {
    //   const reportTitle = this.$t('n_c_data_bank.technologyDataDetails')
    //   ExportPdf.exportPdfDetails(reportTitle, this.technologyData, this, this.technologyData.pdf_image)
    // },
    async getTechnologyDataAllInfo () {
        this.loading = true
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${applicationVerificationShow}/${this.id}`)
        if (result.success) {
            this.technologyData = result.data
        }
        this.loading = false
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
